import React, { Fragment, ReactNode, useCallback, useRef } from 'react';
import cl from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import { IconsEnum } from '../../../assets/icons/types';
import { FormModalCommonProps } from './FormModal.types';
import { ClassName } from '../../../types';

import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';
import { PureButtonHelper } from '../../buttons/PureButtonHelper';
import { PureFormButtonHelper } from '../../buttons/PureFormButtonHelper';
import { Translate } from '../../Translate';

import { words } from '../../../locales/keys';

interface FormModalProps {
  children: ReactNode;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  hideModal: () => void;
  isOpen: boolean;
  modalSize?: 'md' | 'xl' | '2xl' | '5xl' | 'xs' | 'sm' | 'full';
  buttonsWrapperClassName?: ClassName;
  leftButtonsChildren?: ReactNode;
}

function FormModal({
  children,
  disabled,
  form,
  handleCancel,
  handleSubmit,
  hideModal,
  i18nCancelText = words.cancel,
  i18nSubmitText = words.save,
  i18nTitle,
  id,
  isLoading,
  isOpen = false,
  submitDisabled,
  title,
  withoutCancel = false,
  submitButtonColor = 'blue',
  modalSize = 'xl',
  buttonsWrapperClassName,
  leftButtonsChildren
}: FormModalProps & FormModalCommonProps) {
  const divRef = useRef<HTMLDivElement | null>(null);

  const handleKeyPress = useCallback((event) => {
    if (event.which === 13 && event.target.type !== 'textarea') {
      event.preventDefault();
    }
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-20 inset-0"
        id={id}
        initialFocus={divRef}
        open={isOpen}
        data-archi-modal
        onClose={hideModal}
        onKeyPress={handleKeyPress}
      >
        <div
          className="relative flex items-end sm:items-center justify-center h-full pt-8 sm:p-4 sm:pb-8"
          ref={divRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-400 bg-opacity-80 dark:bg-gray-950 dark:bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cl(
                'z-10 flex flex-col bg-white dark:bg-gray-850 border dark:border-gray-800 border-transparent rounded-t-3xl sm:rounded-3xl shadow-xl',
                {
                  'max-h-full w-full': modalSize === 'full',
                  'max-h-full w-full sm:max-w-xl': modalSize === 'xl',
                  'max-h-full w-full sm:max-w-2xl': modalSize === '2xl',
                  'max-h-full w-full sm:max-w-5xl': modalSize === '5xl',
                  'max-h-full w-full sm:max-w-xs': modalSize === 'xs',
                  'max-h-full w-full sm:max-w-sm': modalSize === 'sm',
                  'max-h-full w-full sm:max-w-md': modalSize === 'md'
                }
              )}
            >
              <div className="flex-shrink px-4">
                <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700 py-4">
                  {i18nTitle || title ? (
                    <Dialog.Title
                      as="h3"
                      className="text-base sm:text-lg pl-8 text-center flex-1 truncate"
                    >
                      {i18nTitle ? <Translate id={i18nTitle} /> : title}
                    </Dialog.Title>
                  ) : null}
                  <PureIconButtonHelper
                    className="-mr-2 -my-2 ml-2 dark:hover:bg-gray-700 dark:hover:text-gray-200 dark:text-gray-500 hover:bg-gray-200 hover:text-gray-600 p-2 rounded-full text-gray-400"
                    icon={IconsEnum.X}
                    onClick={hideModal}
                  />
                </div>
              </div>

              {children}

              <div className="flex-shrink">
                <div
                  className={
                    buttonsWrapperClassName ||
                    'flex p-4 space-x-1 justify-between'
                  }
                >
                  <div className="flex space-x-1">{leftButtonsChildren}</div>
                  <div className="flex space-x-1">
                    {withoutCancel ? null : (
                      <PureButtonHelper
                        className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800"
                        disabled={isLoading || disabled}
                        i18nText={i18nCancelText}
                        onClick={handleCancel || hideModal}
                      />
                    )}
                    <PureFormButtonHelper
                      className={cl(
                        'flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md',
                        {
                          'bg-blue-600 hover:bg-blue-700  ':
                            submitButtonColor === 'blue',
                          'bg-green-500 hover:bg-green-600':
                            submitButtonColor === 'green',
                          'bg-red-600 hover:bg-red-500':
                            submitButtonColor === 'red'
                        }
                      )}
                      disabled={isLoading || disabled || submitDisabled}
                      form={form}
                      i18nText={isLoading ? words.processing : i18nSubmitText}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default FormModal;
